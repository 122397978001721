<div class="landing">
  <marquee>{{ marquee }}</marquee>
  <div class="suscribete text-center my-4">
    <div class="mb-2">
      <span class="suscribete__title">SUSCRIBETE A NUESTRO NEWSLETTER</span>
    </div>
    <div class="mb-2">
      <input class="suscribete__input"  [formControl]="email" placeholder="Email" type="email">
    </div>
    <div class="mb-2">
      <button class="btn btn-danger suscribete__btn" (click)="onSubscriber()"> SUSCRIBIRME</button>
    </div>
  </div>
  <div class=" img-container text-center">
    <div class="img-logo d-md-none">
      <div>
        <img
          src="../../../assets/img/logos/webp/logo_original_transp_1600_x_838.webp"
          alt>
      </div>
    </div>
  </div>
</div>


