<div class="wrapper">
    <div class="purchases container-lg imgdos">
        <div class="purchases-content compras" *ngFor="let item of bannerPurchase" >
            <div class="img-purchases">

                <img src="{{ item.img }}" class="img-offers img-fluid" alt="">
            </div>
            <div class="text-purchases">
                <span class="arial">{{ item.promo }} <br> <strong>{{ item.promo1}} </strong></span>

            </div>
        </div>
        <a class="purchases-content compras" href="https://www.sistecredito.com/" rel="noopener noreferrer" target="_blank">
            <div class="img-purchases">

                <img src="assets/img/desktop/webp/sistecredito.webp" class="img-offers img-fluid" alt="">
            </div>
            <div class="text-purchases">
                <span>Método de pago <strong>Sistecrédito</strong></span>

            </div>
          </a>
    </div>
</div>
