import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class ImageConverterService {

  async convertImagesToWebPAndDownload(files: File[]): Promise<void> {
    const zip = new JSZip();

    for (const file of files) {
      const webpData = await this.convertImageToWebP(file);
      const fileName = file.name.split('.').slice(0, -1).join('.') + '.webp';
      zip.file(fileName, webpData.split(',')[1], { base64: true });
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'images.zip');
  }

  private convertImageToWebP(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL('image/webp'));
        };
        img.onerror = (err) => reject(err);
        img.src = event.target.result;
      };
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  }
}
