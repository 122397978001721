<div class="cart-bg mx-0">
    <div class=" p-0 px-lg-3">
        <div class="wrapper-card h-auto">
            <div class="wrapper-card__content border-b">
                <div class="card-wrapper" *ngIf="company">
                    <mat-card class="card-wrapper__mat-card" *ngFor="let items of company; index as i">
                        <mat-card-content class="__card">
                            <div class="card-wrapper__container">
                                <a aria-label="Ir articulos"  routerLink="/articulo/{{ items.product_id }}">
                                    <img src="{{ items.image }}" alt="{{ items.name }}"
                                        class="card-wrapper__img-desktop" />
                                </a>

                                <div class="card-wrapper__content">
                                    <div class="card-info">
                                        <span class="card-info__product card-info__product--color">{{ items.name
                                            }}</span>
                                        <span class="card-info__ref">{{ items.sku }}</span>

                                        <span class="card-info__date">Talla: <span
                                                class="sizes-text" (click)="change('size', items.colors, items.sizes)">{{items.size}}</span></span>
                                        <span class="card-info__date color">Color: <button (click)="change('color', items.colors, items.sizes)" class="btn-color"
                                                mat-raised-button
                                                style="background-color:{{items.color}}; min-width: 20px;">
                                            </button>
                                        </span>
                                        <span class="card-info__price price">{{ items.regular_price | moneda }}</span>
                                        <span class="card-info__date">Añadido a la fecha: 3/05/2021</span>

                                    </div>

                                    <div class="card-amount">
                                        <span class="card-amount__amount">cantidad</span>

                                        <div class="card-amount__btn-items">
                                            <button class="btn-item-down" [disabled]="buttonDisable"
                                                (click)="updateQty(-1, items)">
                                                <i class="fas fa-chevron-down icon-cart"></i>
                                            </button>
                                            <!-- <span class="quantity">Cantidad</span> -->
                                            <input type="number" class="input-items card-amount__input"
                                                value="{{ items.qty }}" min="1" max="999" />
                                            <button class=" btn-item-up" [disabled]="buttonDisable"
                                                (click)="updateQty(+1, items)">
                                                <i class="fas fa-chevron-up icon-cart"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="link-delete">
                                <button class="link-delete__btn" (click)="deleleItem(i,items )">
                                    <span class="link-delete__text">
                                        Eliminar producto
                                    </span>

                                    <img class="link-delete__img" src="assets/img/desktop/webp/delete.webp" alt="">
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="dispatch">
                    <div class="dispatch__option">
                        <img class="dispatch__image" src="assets/img/desktop/webp/envio.webp" alt="">
                        <span class="dispatch__title">Despacho a domicilio</span>
                        <!-- <span class="dispatch__subtitle">Disponible</span> -->
                    </div>
                    <div class="dispatch__option">
                        <img class="dispatch__image" src="assets/img/desktop/webp/tienda.webp" alt="">
                        <span class="dispatch__title">Retiro en tienda</span>
                        <!-- <span class="dispatch__subtitle">Disponible</span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-discount">
            <h4 class="mb-2">Código de descuento</h4>
            <form action="">
                <div class="discount">
                    <input type="text" class="shadow-sm" name="" id="" placeholder="Ingresar código de descuento">
                    <button>
                        Aplicar
                    </button>
                </div>
            </form>
        </div>

    </div>

</div>
