<div class="nav-content nav-home" [ngClass]="{ 'darkness':dark}">
  <header class="nav-wrapper  container-md "
    [ngClass]="{ open: isOpenMenu, isLoggedIn: isLoggedIn}"
    [ngClass]="{ space: 'space'}"
    >
    <div class="nav-container ">
      <div class="nav-logo-container">
        <div class="topbar-logo d-none d-lg-block">
          <a routerLink="/">
            <img src="assets/img/logos/gif/gif-logo.gif" class="img-logo"
              alt="Logo GiovanYe">
          </a>
        </div>
        <div class="img-logo-center"  routerLink="/">
          <gvy-logo [textDark]="logoTexColor" ></gvy-logo>
        </div>

        <div class="nav-actions">

          <div [ngClass]="styleClass">
            <i class="fa-solid fa-circle-notch fa-spin"
              style="--fa-animation-duration: 0.45s;" *ngIf="showSpinner"></i>
            <input type="text" (click)="searchProduct(query.value)"
              [formControl]="search" #query
              (keyup.enter)="goto(query.value)" placeholder="Buscar.."
              class="form-control">
          </div>

          <div class="result-search animate__animated animate__fadeIn"
            *ngIf="productsFound.length > 0 && showSearch">
            <div class="result-search__box" *ngFor="let item of productsFound"
              (click)="onClickSearch(item.slug)">
              <img width="100%" src="{{item.image}}" alt>
              <div class="result-search__info">
                <div> <span> <strong> {{item.name }}</strong></span> {{
                  item.short_description}}</div>
                <div class="result-search__price">Precio: {{ item.regular_price
                  | number }}</div>
              </div>
            </div>
          </div>

          <ul class="topbar-section" role="list">
            <li role="listitem" class="d-lg-block listitem">
              <div class="pointer listitem__icon" (click)="showSearchBox()">
                <!-- TODO FIX  -->
                <img src="assets/img/sales/search-icon-white.webp"
                  alt="Favoritos" title="Favoritos" class>
              </div>
            </li>
            <li role="listitem" class="d-none d-lg-block listitem">
              <a routerLink="/favoritos" class="listitem__icon">
                <img src="assets/img/desktop/webp/corazon.webp"
                  alt="Favoritos" title="Favoritos" class>
              </a>
            </li>
            <li role="listitem"
              class="user-menu-icon show-only-desktop listitem">
              <a routerLink="perfil"
                class="custom-nav-list-item listitem__icon">
                <span>
                  <img src="{{ user?.image }}"
                    class="img-icon img-rounded rounded-circle" alt="image"
                    width="40px" />
                </span>
                <span class="custom-nav-list-item-text-wrapper">
                  <span class="custom-nav-list-item-text">
                    <span>{{ user?.name + " " + user?.last_name }}</span>
                  </span>
                </span>
              </a>
            </li>

            <li role="listitem" *ngIf="isLoggedIn" class="listitem">
              <a routerLink="#" (click)="logOut()" class="listitem__icon">
                <img src="assets/img/desktop/webp/usuario-on-line.webp"
                  alt="Cerrar sesión" class
                  title="Cerrar sesión">
              </a>
            </li>
            <li role="listitem" class="d-none d-lg-block listitem"
              *ngIf="!isLoggedIn">
              <a routerLink="/login" class="listitem__icon">
                <img src="assets/img/desktop/webp/usuario.webp"
                  alt="Iniciar sesión" class title="Iniciar sesión">
              </a>
            </li>
            <li role="listitem" class="d-none d-lg-block listitem">
              <a routerLink="/carrito-de-compras" class="listitem__icon">
                <img src="assets/img/desktop/webp/carrito.webp"
                  alt="Carrito de compras" class
                  title="Carrito de compras"> <span class="cart-items-number"
                  *ngIf="itemsCart">{{
                  itemsCart }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="topbar-wrapper d-none d-lg-flex  ">
        <div class="menu-category-wrapper">
          <div class="menu-category " *ngFor="let category of categories">
            <button
              class="dropbtn font-arial animate__fadeIn"
              (mouseover)="onHoverMenu()"
              (click)="categorySelected(category)"
              routerLink="/lista-articulos/{{category.slug}}"
              [ngStyle]="{ 'background-color': bgColor, 'color': changeFontColor(category.name) }">{{
              category.name}}
            </button>

            <div class="menu-category-content  animate__fadeIn "
              [ngClass]="{'hide': addClass}"
              *ngIf="category.subCategories && category.subCategories.length > 0">
              <div class="row-category animate__fadeIn ">
                <div class="col-category container row animate__fadeIn ">
                  <div class="categories-list col-4">
                    <div>
                      <a class="font-arial" (click)="onMenuClick()"
                        (mouseover)="onHover(subCategory)"
                        routerLink="/lista-articulos/{{subCategory.slug}}"
                        *ngFor="let subCategory of category.subCategories">{{subCategory.name}}</a>
                    </div>
                  </div>
                  <div class="col-2"></div>

                  <div class="img-menu-category col-6">
                    <owl-carousel-o [options]="promoNav"
                      *ngIf="productsCategories && productsCategories.length > 0">
                      <ng-template carouselSlide
                        *ngFor="let img of productsCategories">
                        <div class="img">
                          <img [src]="img" alt="Logo-Giovanye">
                        </div>
                      </ng-template>
                    </owl-carousel-o>
                    <!-- <app-carousel-img [images]="productsCategories"></app-carousel-img> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-4">
          <button
          class="btn-store font-arial"
          routerLink="/localiza-tu-tienda"
          [ngStyle]="{ 'background-color': bgColor, 'color': changeFontColor('') }"
          >store</button>
        </div>
      </div>

      <div class="show-mobile d-lg-none" *ngIf="!isLoggedIn">
        <div class="side-menu mobile">
          <div class="menu-text-top">
            <h3 class="text-white">MENÚ</h3>
            <button class="btn-close-menu" (click)="closeMenu()">X</button>
          </div>

          <div class="accordion-wrapper">
            <div class="accordion-section">
              <div class="accordion arrows" (click)="categorySelected(category)"
                *ngFor="let category of categories">
                <input type="radio" name="accordion" id="{{category.id}}" />
                <section class="box">
                  <label class="box-title" for="{{category.id}}">
                    <a class="categories-name" (click)="closeMenu()"
                      routerLink="/lista-articulos/{{category.slug}}">{{
                      category.name }}</a>
                  </label>
                  <label class="box-close" for="acc-close"></label>
                  <ul>
                    <li class="box-content"><a class="link-menu"
                        (click)="closeMenu()"
                        routerLink="/lista-articulos/{{subCategory.id}}"
                        *ngFor="let subCategory of category.subCategories">{{subCategory.name}}</a>
                    </li>
                  </ul>
                </section>
                <input type="radio" name="accordion" id="acc-close" />
              </div>
            </div>
            <div class="menu-actions">
              <span> <a routerLink="/login" routerLinkActive="active">
                  MI CUENTA/ Ingresar
                </a>
              </span>
              <div class="menu-actions-list">
                <a class="topbar-menu-link"
                  routerLink="/carrito-de-compras">CARRITO<span
                    class="cart-items-number"
                    *ngIf="itemsCart">{{
                    itemsCart }}</span></a>
                <a class="topbar-menu-link" routerLink="/favoritos">LISTA DE
                  FAVORITOS</a>
                <a class="topbar-menu-link"
                  routerLink="https://wa.link/20cfzr">WHATSAPP</a>
                <a class="topbar-menu-link"
                  routerLink="https://wa.link/20cfzr">ATENCIÓN AL CLIENTE </a>
                <a class="topbar-menu-link"
                  routerLink="/localiza-tu-tienda">LOCALIZA TU TIENDA</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </header>
  <div *ngIf="isLoggedIn">
    <span *ngIf="itemsCart && itemsCart > 0" class="cart-item-2">
      <a routerLink="/carrito-de-compras">
        <img src="assets/img/desktop/webp/carrito.png"
          alt="Carrito de compras" title="Carrito de compras">
        <span class="cart-items-number">{{ itemsCart }}</span>
      </a>
    </span>
  </div>
</div>

<!-- MOBILE ========================================================================================================================-->
<div *ngIf="isLoggedIn">
  <div class="show-mobile" [class.active]="activeMenu">
    <div class="mobile-wrapper">
      <div>
        <!-- <div class="vendortitle">Menú</div> -->
        <button class="menu-icon h-auto" (click)="toggleMenu2()">
          <img class="img-fluid" src="assets/img/desktop/webp/menu.png"
            alt="Giovanye logo" />
        </button>
      </div>
      <a id="logo" routerLink="/">
        <!-- <img src="{{logo}}" alt="Obbro logo" /> -->
        <img class="img-fluid" src="assets/img/desktop/webp/logo_blanco.png"
          alt />
      </a>
      <div></div>
    </div>
    <div class="side-menu animate__fadeIn" [class.active]="activeMenu">
      <div class="menu-text-top">
        <h3>MENÚ</h3>
        <button class="btn-close-menu" (click)="toggleMenu2()">X</button>
      </div>

      <div class="accordion-wrapper">
        <div class="accordion-section">
          <div class="accordion arrows" (click)="categorySelected(category)"
            *ngFor="let category of categories">
            <input type="radio" name="accordion" id="{{category.id}}" />
            <section class="box">
              <label class="box-title" for="{{category.id}}">
                <a href="/lista-articulos/{{category.slug}}">{{
                  category.name }}</a>
              </label>
              <label class="box-close" for="acc-close"></label>
              <ul>
                <li class="box-content"><a class="link-menu"
                    href="/lista-articulos/{{subCategory.id}}"
                    *ngFor="let subCategory of category.subCategories">{{subCategory.name}}</a>
                </li>
              </ul>
            </section>
            <input type="radio" name="accordion" id="acc-close" />
          </div>
        </div>
        <div class="menu-actions">
          <span>MI CUENTA
            {{ userData.name }}/ <a routerLink="/logout"
              routerLinkActive="active">
              <i class="fas fa-sign-out-alt logout ml-1"></i>
            </a>
          </span>
          <div class="menu-actions-list">
            <a class="topbar-menu-link" href="/carrito-de-compras">CARRITO<span
                class="cart-items-number"
                *ngIf="itemsCart">{{
                itemsCart }}</span></a>
            <a class="topbar-menu-link" href="/favoritos">LISTA DE FAVORITOS</a>
            <a class="topbar-menu-link"
              href="https://wa.link/20cfzr">WHATSAPP</a>
            <a class="topbar-menu-link" href="https://wa.link/20cfzr">ATENCIÓN
              AL CLIENTE </a>
            <a class="topbar-menu-link" href="/localiza-tu-tienda">LOCALIZA TU
              TIENDA</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="user-menu" *ngIf="isOpenMenuDesktop">
  <mat-list class="user-menu-list">
    <mat-list-item *ngFor="let option of options">
      <a routerLink="{{ option.link }}" (click)="isOpenMenuDesktop=false">
        <img class="option-icon" src="{{ option.icon }}" />
        <span class="option-label">{{ option.label }}</span>
      </a>
    </mat-list-item>
  </mat-list>
</div>
