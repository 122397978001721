import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MessageService } from '@services/message.service';

@Component({
  selector: 'gvy-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule
  ]
})
export class FilterComponent {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() colors: any[] = []
  @Output() onColorSelected = new EventEmitter()
  @Output() onOrderSelected = new EventEmitter()

  constructor(
    private messageService: MessageService,

  ) {
  }


  public filterByColors(color: string) {
    this.onColorSelected.emit(color)
  }
  filterArray(products: any[], color: string): any[] {
    const filter = products.filter((e) => {
      const filterSub: any[] = e.colors.filter(
        (c) => c.name.toLowerCase() === color
      );
      return filterSub.length > 0;
    });
    return filter;
  }
  order(direction: string) {
    this.onOrderSelected.emit(direction)
  }
}
