import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../core/services/Data/data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'gvy-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class LogoComponent implements OnInit {
  public textDark = false
  public title = 'GiovanYe'
  public subtitle = 'world'
  public isCelular = false;
  public isDark= false;
  constructor(
    private dataService: DataService,
    private breakpointObserver: BreakpointObserver
  ) { }
  ngOnInit(): void {
    this.dataService.getChangeColorToWhite$().subscribe(data => {
      console.log('data', data);
      this.isDark = data == 'dark'
      this.textDark =  this.isDark && !this.isCelular
    })
    this.breakpointObserver
    .observe(`(max-width: 768px)`)
    .subscribe(result => {
      console.log('result', result);
      this.isCelular = result.matches;
      this.textDark =  this.isDark && !this.isCelular
    });
  }

}
