import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Route, Router } from '@angular/router';
import { UserFacadeService } from '@facades/auth/user-facade.service';
import { VendorFacadeService } from '@root/features/vendor/vendor.facade.service';
import { Subscription } from 'rxjs';
import { NavService } from './../nav/nav.service';
import { environment } from '@environments/environment';
import { VendorModel } from '@models/vendor.model';
import { ProductFacadeService } from '../product/product-facade.service';
import { UtilsService } from '@root/utilities/utils.service';
import Swal from 'sweetalert2';
import { CartFacadeService } from '@root/pages/cart/cart-facade.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    panelOpenState = false;

    @Input() menuIsOpen = true;
    @ViewChild('searchInput') searchInput: ElementRef;
    isLoggedIn = true;
    totalProducts = 0;
    subs: Subscription = new Subscription();
    data: any;
    items: number;
    logo = environment.STORAGE + 'settings/July2020/IcpuJ5H6KNDs3bz4Yj5W.png';
    vendor: VendorModel;
    textColor='black'
    constructor(
        private navService: NavService,
        private router: Router,
        private cartServiceFacade: CartFacadeService,
        private userFacade: UserFacadeService,
        private vendorFacade: VendorFacadeService,
        private productFacade: ProductFacadeService,
        private loading: UtilsService
    ) {}
    ngOnDestroy(): void {
        // this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.getitems();
      this.textColor='black'
    }

    /**
     * Toggle Menu
     */
    toggleMenu(): void {
        this.menuIsOpen = !this.menuIsOpen;
        this.navService.toggleMenu();
    }
    getitems() {
        let sub: Subscription = this.cartServiceFacade
            .getCart$()
            .subscribe((items) => {
                if (items) {
                    this.totalProducts = 0;
                    items.forEach((element) => {
                        this.totalProducts += element.qty;
                    });
                }
            });
        this.subs.add(sub);
        let sub2: Subscription = this.userFacade
            .getCurrentUser$()
            .subscribe((user) => (this.isLoggedIn = user !== null));
        this.subs.add(sub2);
        let sub3: Subscription = this.vendorFacade
            .getCurrentVendor$()
            .subscribe((vendor) => {
                this.vendor = vendor;
            });
        this.subs.add(sub3);
    }

    search() {
        if (this.searchInput.nativeElement.value !== '') {
            this.loading.loading();
            this.productFacade.searchProductByQuery(
                this.searchInput.nativeElement.value
            );
            this.searchInput.nativeElement.value = null;
            this.router.navigateByUrl('articulos');
        }
    }

    goToCart() {
        if (this.totalProducts == 0) {
            Swal.fire(
                'CARRITO DE COMPRAS',
                'El Carrito de compras está vacio',
                'info'
            );
            return;
        }
        this.router.navigateByUrl('/carrito-de-compras');
    }
}
