import { IMenuCategory } from "@interfaces/menu-categories-interfaces";


export const menuCategoryMocks: IMenuCategory[] = [
    {
        image: "../../../assets/img/desktop/webp/jumbotron.webp",
    },
    {
        image: "../../../assets/img/desktop/webp/jumbotron_2.webp",
    }
    ,
    {
        image: "../../../assets/img/desktop/webp/jumbotron.webp",
    }
    ,


]
