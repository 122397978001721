import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gvy-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  constructor(){}
  ngOnInit(): void {
  }

}
