import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '@root/shared/shared.module';
import { DataService } from '../../core/services/Data/data.service';
import { FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'gvy-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class LandingComponent implements OnInit, OnDestroy {
  public email: FormControl;
  public title = 'Giovanye';
  public scrolling: boolean = false;
  public imgSelected: string[] = []
  public marquee = 'ENVIO GRATIS POR COMPRAS IGUALES O MAYORES A $ 400.000    TIENDAS UBICADAS EN MEDELLÍN, PASTO. CÚCUTA, PEREIRA, BELLO, IPIALES, SAMANIEGO, NARIÑO, POPAYÁN.  LINEAS DE ATENCION WHATSAPP 304 4191740   '
  constructor(
    public translate: TranslateService,
    private dataService: DataService,
    private userProfileService: UserProfileService
  ) {
    translate.setDefaultLang('es');
    this.email = new FormControl(null, Validators.email);
  }
  ngOnDestroy(): void {
    this.dataService.setChangeColorToWhite('white')
    console.log("ngOnDestroy");
  }
  ngOnInit(): void {
    this.onScroll;
    this.dataService.setChangeColorToWhite('dark')
  }
  public onScroll($event: any): void {
    this.scrolling = false;
    if ($event.srcElement.children[0].scrollTop !== 0) {
      this.scrolling = true;
    };
    if ($event.srcElement.children[0].scrollTop == 0) {
      this.scrolling = false;
    }
  }

  listener(evento: Event) {

  }
  onSubscriber() {
    if (this.email.status === 'INVALID') {
      Swal.fire(
        'Correo inválido',
        'Ingrese un correo electrónico válido',
        'error'
      );

      return;
    }
    this.email.disable();
    const email = {
      email: this.email.value,
      name: 'Usuario Suscribete',
      last_name: 'Usuario Suscribete',
      role_id: '2',
    };
    this.userProfileService.createSuscribete(email).subscribe(
      (resp) => {
        Swal.fire(
          'Bienvenido',
          'Felizmente ya estas suscrit@, pronto recibira noticias nuestras.',
          'success'
        );
        this.email.enable();
      },
      (error) => {
        console.log(error);
        this.email.enable();
        Swal.fire(
          'Error',
          error.error.message,
          'error'
        );
      }
    );
  }
}

