<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <i class="fa-solid fa-sliders"></i>
        &nbsp;&nbsp;&nbsp;Mostrar Filtro
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row">
      <div class="col-12">
        <span>Ordenar por</span>
        <div class>
          <div class="order-filter">
            <a class="dropdown-item" (click)="order('asc')">a - z</a>
            <a class="dropdown-item" (click)="order('desc')">z - a</a>
          </div>
        </div>
        <span>Filtrar por</span>
        <div
          class="filter-content-items animate__fadeIn"
          *ngFor="let color of colors">
          <div class="dropdown-item colors"
            (click)="accordion.closeAll()"
            (click)="filterByColors(color.name.toLowerCase())">
            <div>
              {{ color.name | titlecase }}
            </div>

            <div *ngIf="color.code"
              class="item-colors"
              [ngStyle]="{'background': color.code}">
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>
