<div>
  <div class="networks">
    <div class="networks-title">
      <div>
        <a href="/"><img
          src="assets/img/logos/webp/logo_original_transp_1600_x_838.webp"
          alt /></a>
      </div>
      <div class="networks-content">
        <h3 class="font-text">SÍGUENOS EN:</h3>
        <div class="footer-information {{footerListItem.class}}"
          *ngFor="let footerListItem of footerList">
          <ul *ngFor="let item of footerListItem.items" role="list"
            class="container items-list">
            <li role="listitem" class="list-item"
              *ngFor="let item of item.socialItems">
              <a href="{{ item.link }}" rel="noopener noreferrer"
                target="{{  item?.target  }}">
                <i class="fa-brands {{ item.image }}"></i> </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
