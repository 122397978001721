import { FooterSocialNetworksComponent } from './components/footer-social-networks/footer-social-networks.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AdsBlockComponent } from './components/ads-block/ads-block.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { FeaturedCompaniesSliderComponent } from './components/featured-companies-slider/featured-companies-slider.component';
import { FeaturedProductsSliderComponent } from './components/featured-products-slider/featured-products-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from './material.module';
import { NavComponent } from './components/nav/nav.component';
import { SliderComponent } from './components/slider/slider.component';
import { TypeListComponent } from './components/type-list/type-list.component';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';
import { TitleComponent } from './components/title/title.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyCardComponent } from './components/company-card/company-card.component';
import { ProductsCardComponent } from './components/products-card/products-card.component';
import { FilterProductComponent } from './components/filter-product/filter-product.component';
import { FilterListProductComponent } from './components/filter-list-product/filter-list-product.component';
import { CartItemsComponent } from './components/cart-items/cart-items.component';
import { PaymentMethodItemsComponent } from './components/payment-method-items/payment-method-items.component';
import { LastPurchasesItemsComponent } from './components/last-purchases-items/last-purchases-items.component';
import { NotifierComponent } from './components/notifier/notifier.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RouterModule } from '@angular/router';
import { SinResultadosComponent } from './components/sin-resultados/sin-resultados.component';
import { AdvertisingComponent } from './components/advertising/advertising.component';
import { FormNewPasswordComponent } from '@root/auth/components/form-new-password/form-new-password.component';
import { MonedaPipe } from './components/moneda.pipe';
import { MatIconModule } from '@angular/material/icon';
import { CardCategoriesComponent } from './components/card-categories/card-categories.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { FeatureAccessoriesSliderComponent } from './components/feature-accessories-slider/feature-accessories-slider.component';
import { FeaturedAccesoriesCardComponent } from './components/featured-accesories-card/featured-accesories-card.component';
import { PurchaseBannerBetaComponent } from './components/purchase-banner-beta/purchase-banner-beta.component';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { SliderPromoComponent } from './components/slider-promo/slider-promo.component';
import { BannerInfoComponent } from './components/banner-info/banner-info.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { FooterLinksPoliciesComponent } from './components/footer-links-policies/footer-links-policies.component';
import { HoardingComponent } from './components/hoarding/hoarding.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CarouselImgComponent } from './components/nav/carousel-img/carousel-img.component';
import { ModalCustomComponent } from './components/modal-custom/modal-custom.component';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { SizesComponent } from './components/sizes/sizes.component';
import { ColorsComponent } from './components/colors/colors.component';
import { WhatsappFloatComponent } from './components/whatsapp-float/whatsapp-float.component';
import { OnlylettersDirective } from '@root/core/directives/onlyletters.directive';
import { SizesButtonsComponent } from '@root/pages/product-home/product-description/components/sizes-buttons/sizes-buttons.component';
import { ImgViewerComponent } from '@root/pages/product-home/product-description/img-viewer/img-viewer.component';
import { ProductDescriptionComponent } from '@root/pages/product-home/product-description/product-description.component';
import { LogoComponent } from './components/logo/logo.component';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslateModule,
  MaterialModule,
  FormsModule,
  CarouselModule,
  ReactiveFormsModule,
  MatIconModule,
  NgxSkeletonLoaderModule,
  NgbPaginationModule,
  NgbAlertModule,
  SizesButtonsComponent,
  LogoComponent
];
const COMPONENTS = [
  AdsBlockComponent,
  CopyrightComponent,
  FeaturedCompaniesSliderComponent,
  FeaturedProductsSliderComponent,
  FooterComponent,
  HeaderComponent,
  NavComponent,
  SliderComponent,
  TypeListComponent,
  TitleComponent,
  CompaniesComponent,
  CompanyCardComponent,
  UserDropdownComponent,
  ProductsCardComponent,
  FilterListProductComponent,
  FilterProductComponent,
  CartItemsComponent,
  ProductDescriptionComponent,
  PaymentMethodItemsComponent,
  LastPurchasesItemsComponent,
  NotifierComponent,
  //LoadingComponent,
  SinResultadosComponent,
  AdvertisingComponent,
  FormNewPasswordComponent,
  MonedaPipe,
  CardCategoriesComponent,
  CategoriesComponent,
  FeatureAccessoriesSliderComponent,
  FeaturedAccesoriesCardComponent,
  PurchaseBannerBetaComponent,
  AddressModalComponent,
  SliderPromoComponent,
  BannerInfoComponent,
  SubscribeComponent,
  FooterLinksPoliciesComponent,
  FooterSocialNetworksComponent,
  HoardingComponent,
  CarouselImgComponent,
  ModalCustomComponent,
  ImgViewerComponent,
  SizesComponent,
  ColorsComponent,
  WhatsappFloatComponent,
  OnlylettersDirective
];
@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }

// // required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }
