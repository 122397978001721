import { ICardProduct } from '@interfaces/card-products-interfaces';


export const productMocks: ICardProduct[] = [
    {
        id: 1,
        image: "../../../assets/img/desktop/webp/slider-18.webp",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas: [
            "S", "M", "L", "XL"
        ],
        favorite: true,
        new: true,
        dcto: true
    },
    {
        id: 1,
        image: "../../../assets/img/desktop/webp/slider-16.webp",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas: [
            "S", "M", "L", "XL"
        ],
        favorite: false,
        new: false,
        dcto: true
    }
    ,
    {
        id: 1,
        image: "../../../assets/img/desktop/webp/slider-13.webp",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas: [
            "S", "M", "L", "XL"
        ],
        favorite: false,
        new: false,
        dcto: true
    }
    ,
    {
        id: 1,
        image: "../../../assets/img/desktop/webp/slider-14.webp",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas: [
            "S", "M", "L", "XL"
        ],
        favorite: false,
        new: false,
        dcto: true
    },
    {
        id: 1,
        image: "../../../assets/img/desktop/webp/slider-06.webp",
        nameProduct: "Pantalon",
        ref: "8399384",
        price: 74000,
        tallas: [
            "S", "M", "L", "XL"
        ],
        favorite: true,
        new: false,
        dcto: true
    },

]
