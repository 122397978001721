<div class="header-wrapper"
  [ngClass]="{ 'menu-is-open': false ,isLoggedIn: isLoggedIn }"
  *ngIf="!isLoggedIn">
  <div class="header-navigation">
    <div (click)="toggleMenu()">
      <button class="menu-icon">
        <img class="img-fluid" src="assets/img/desktop/webp/menu.webp"
          alt="Logo GiovanYe" />

      </button>
    </div>
    <div>
      <button (click)="goToCart()" attr.aria-label="{{
            'header.cart-label'
                | translate: { totalProducts: totalProducts }
        }}" class="menu-icon-cart" *ngIf="vendor">
        <img src="/assets/img/cart-icon-white.webp" alt>
        <span class="badget" *ngIf="totalProducts >= 0">
          {{totalProducts}}
        </span>
      </button>
    </div>
  </div>

  <div class="header-logo-wrapper">
    <div class="header-logo">
      <a id="logo" routerLink="/">
        <gvy-logo [textColor]="textColor"></gvy-logo>
      </a>
    </div>
  </div>
</div>
