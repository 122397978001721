import { ISliderPromo } from "@interfaces/slider-promo-interface";

export const promoBannerMocks: ISliderPromo[] = [
    {
        id: 1,
        promo: "Compra",
        promo1: "100% segura",
        img: "../../../assets/img/desktop/webp/compra.webp"
    },
    {
        id: 1,
        promo: "Múltiples",
        promo1: "Medios de pagos",
        img: "../../../assets/img/desktop/webp/medios_pago.webp"
    },
    {
        id: 1,
        promo: "Envíos gratis",
        promo1: "$400.000",
        img: "../../../assets/img/desktop/webp/envios.webp"
    },
    // {
    //     id:1,
    //     promo:"Métodos de pago",
    //     promo1:"Sistecrédito",
    //     img:"../../../assets/img/desktop/webp/sistecredito.webp"
    // },

]
