import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import localeCo from '@angular/common/locales/es';
registerLocaleData(localeCo);

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FeaturedProductsComponent } from './features/featured-products/featured-products.component';
import { FrequentQuestionComponent } from './features/frequent-question/frequent-question.component';
import { FeaturedProductsCompanyComponent } from './features/featured-products-company/featured-products-company.component';
import { PaymentMethodComponent } from './features/payment-method/payment-method.component';
import { TraceGuideComponent } from './features/trace-guide/trace-guide.component';
import { GuideNotLocatedComponent } from './features/guide-not-located/guide-not-located.component';
import { GuideInfoComponent } from './features/guide-info/guide-info.component';
import { LastPurchasesResumenComponent } from './features/last-purchases-resumen/last-purchases-resumen.component';
import { CoreModule } from './core/core.module';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
// Login con Redes

import { environment } from '@environments/environment';
import { ProductAddCartComponent } from './features/product-add-cart/product-add-cart.component';
import { PickUpInStoreComponent } from './features/pick-up-in-store/pick-up-in-store.component';

import { ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, GoogleLoginProvider, FacebookLoginProvider, SocialAuthServiceConfig } from '@alenosmanovic91x/angularx-social-login';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { ProductComponent } from "./shared/components/product/product.component";
import { AssignedConsultantComponent } from './features/assigned-consultant/assigned-consultant.component';




// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FeaturedProductsComponent,
    FeaturedProductsCompanyComponent,
    FrequentQuestionComponent,
    PaymentMethodComponent,
    TraceGuideComponent,
    GuideNotLocatedComponent,
    GuideInfoComponent,
    LastPurchasesResumenComponent,
    ProductAddCartComponent,
    PickUpInStoreComponent,
    SearchResultComponent,
    AssignedConsultantComponent,
  ],
  imports: [
    LoadingComponent,
    GooglePlaceModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SocialLoginModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    ProductComponent
],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.CLIENT_ID_GOOGLE
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.CLIENT_ID_FACEBOOK)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
