<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-sm-6">
            <div class="jumbotron jumbotron-fluid d-flex justify-content-center">
                <div class="container text-center">
                    <img class="" src="assets/img/desktop/webp/cart2.webp" alt="">
                    <h1 class="display-5 mt-5">{{ title }}</h1><p> {{ detail }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
