import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ImageConverterService } from '@services/image - converter/image-converter.service';
import imageCompression from 'browser-image-compression';
import * as JSZip from 'jszip';
@Component({
  selector: 'gvy-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class ToolsComponent implements OnInit {
  files: File[] = [];

  constructor(private imageConverterService: ImageConverterService) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  onFilesSelected(event: Event): void {
    console.log('Imagen leida');
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const files = Array.from(input.files);
      this.convertImagesToWebP(files);
    }
  }
  //  PRIMER METODO
  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.files = Array.from(input.files);
    }
  }

  async downloadZip(): Promise<void> {
    await this.imageConverterService.convertImagesToWebPAndDownload(this.files);
  }

  // SEGUNDO METODO
  async convertImagesToWebP(files: File[]): Promise<void> {
    console.log('Convirtiendo Imagen leida');
    const zip = new JSZip(); // Crear una instancia de JSZip
    // const options = {
    //   maxSizeMB: 1, // Tamaño máximo de la imagen en MB
    //   maxWidthOrHeight: 1920, // Dimensiones máximas
    //   useWebWorker: true, // Utilizar web worker para mejorar el rendimiento
    //   fileType: 'image/webp' // Formato de salida
    // };
    const options = {
      maxSizeMB: 0.5, // Tamaño máximo reducido a 0.5 MB
      maxWidthOrHeight: 1920, // Dimensiones reducidas para optimizar el tamaño
      useWebWorker: true, // Utilizar web worker para mejor rendimiento
      fileType: 'image/webp', // Formato de salida
      initialQuality: 0.80, // Reducir calidad inicial (entre 0 y 1)
      alwaysKeepResolution: true // Permitir reducir la resolución para una compresión adicional
    };

    for (const file of files) {
      try {
        const compressedFile = await imageCompression(file, options);
        const webpFile = new File([compressedFile], `${file.name.split('.')[0]}.webp`, {
          type: 'image/webp'
        });

        console.log('Imagen convertida:', webpFile);
        const arrayBuffer = await webpFile.arrayBuffer();
        zip.file(webpFile.name, arrayBuffer);
        // this.downloadFile(webpFile);
        // Aquí puedes subir el archivo o guardarlo donde lo necesites
      } catch (error) {
        console.error('Error al convertir la imagen:', error);
      }
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(content);

      link.href = url;
      link.download = 'imagenes_convertidas.zip'; // Nombre del archivo ZIP
      document.body.appendChild(link);
      link.click();

      // Limpiar el objeto URL
      // document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });

  }
  private downloadFile(file: File): void {
    console.log('Creando link de Imagen leida');
    const link = document.createElement('a');
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name; // Nombre del archivo con extensión .webp
    document.body.appendChild(link);
    link.click();

    // Limpiar el objeto URL
    // document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}
