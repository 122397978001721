<div class="product-wrapper" *ngIf="product">
  <div #imageParentMain class="row">
    <div class="col-12 col-lg-5">
      <div #imageParent class="carousel-content">
        <div class="carousell-wrapper carousel-vertical">
          <owl-carousel-o [options]="carouselLeft" class="carouselVertical">
            <ng-template carouselSlide *ngFor="let image of imagenes">

              <div class="vertical-imagen">
                <img (click)="cambiaImg(image)" src="{{ image }}" />
              </div>

            </ng-template>
          </owl-carousel-o>
        </div>
        <div class="img-select-wrapper">
          <div #image class="img-select"
            style="--img: url({{ imgSelected }});  background-image: var(--img )  "
            (click)="showModal(content)">
          </div>
        </div>

      </div>
    </div>

    <div class=" col-12 col-lg-7 product-info-wrapper">
      <div class="product-info">
        <div class="product-info-title font-text">
          <div class="product-info-title-name">
            <span class>{{ product.name | uppercase}}</span>
            <span class="rating d-none d-lg-block">
              <i class="fas fa-star icons"></i>
              <i class="fas fa-star icons"></i>
              <i class="fas fa-star icons"></i>
              <i class="fas fa-star icons"></i>
              <i class="fas fa-star icons"></i>
              <a href class="rating__reviews">Escribe un comentario</a>
            </span>
          </div>
          <small class=" d-none d-lg-block ref">Referencia:&nbsp;&nbsp;&nbsp; {{
            product.sku
            }}</small>

          <small>{{ price | moneda }}</small>
        </div>
        <div class="sistecredito ml-lg-2">
          <img src="assets/img/desktop/webp/sistecredito.webp" alt>
          <a href="https://www.sistecredito.com/" rel="noopener noreferrer"
            target="_blank">
            Compralo ya y pagalo en nuestro canal Sistecrédito
            <span class>Ver más</span>
          </a>
        </div>

        <div class="sizes font-title">
          <span class="sizes__title">COLOR</span>
          <div class="sizes__btn">
            <button title="{{ color.name }}" class="custom-btn" *ngFor="let color of colors"
              mat-raised-button matBadge="1" [matBadgeHidden]="color.selected"
              (click)="selected(color)"
              style="background-color:{{color.code}};">
            </button>
          </div>
        </div>
        <div class="sizes font-title">
          <span class="sizes__title">Talla</span>
          <div class="sizes__btn">
            <gvy-sizes-buttons
              [sizes]="sizes"
              (onSelected)="onSizeSelected($event)"></gvy-sizes-buttons>

            <a
              class="sizes-guide"
              href="../../../../assets/files/tabla de medidas GIOVANYE .pdf">
              GUIA DE TALLAS
              <img
                class="sizes-guide__image"
                src="../../../../assets/img/desktop/webp/rule.webp"
                alt>
            </a>
          </div>
        </div>
        <div *ngIf="product.stock_status ==1">
          <div class="card-amount d-none">
            <span class="card-amount__amount"
              *ngIf="existencia && existencia > 0">Cantidad</span>
            <span class="card-amount__not-available"
              *ngIf="!existencia || existencia <= 0">NO
              HAY
              DISPONIBILIDAD</span>
            <div class="card-amount__btn-items"
              *ngIf="existencia && existencia > 0">
              <button class="btn-decrement"
                [disabled]="product.stock - quantity<=product.stock_min"
                (click)="cantidad(-1)">-</button>
              <input class="card-amount__input input-items" disabled
                type="number" value="{{ quantity }}" min="1"
                max="999">
              <button class="btn-increment"
                [disabled]="product.stock - quantity<=product.stock_min"
                (click)="cantidad(+1)">+</button>

            </div>
          </div>
          <div class="btn-wrapper" *ngIf="existencia && existencia > 0">
            <button type="button" *ngIf="product.stock_status ==1  && !isbadget"
              [disabled]="!availableProduct"
              class="btn-buy btn btn-block btn-warning"
              (click)="tobuy(product)">
              <span class="btn__text" *ngIf="!loading">{{ availableProduct ?
                'Compra Rápida' :
                '¡AGOTADO!' }}</span>
              <mat-spinner *ngIf="loading" [diameter]="35"
                [color]="'primary'"></mat-spinner>
            </button>
            <button class="btn-favorite mx-lg-4" routerLink="/favoritos">
              <img class="img-fluid"
                src="assets/img/desktop/webp/favorite.webp" alt>
            </button>
            <button class="icon-share" routerLink="/favoritos">
              <i class="fa-solid fa-share-nodes"></i>
            </button>
          </div>
        </div>
        <div class="font-title accordion">
          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
              (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="descrip">DESCRIPCIÓN Y DETALLES</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p [innerHTML]="product.description">{{ product.description }}</p>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              *ngFor="let item of panelDescriptions">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span class="descrip"> {{ item.titleDescription }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p> {{ item.textDescription }}</p>
              <p> {{ item.list }}</p>
              <p> {{ item.subTitleDescription }}</p>
              <p> {{ item.subText }}</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <div class="modal-title text-center">Galería</div>
    <span aria-label="Close" (click)="modal.dismiss('Cross click')"><i
        class="fa fa-times"></i></span>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="container-img">
        <img [src]="item" alt *ngFor="let item of imagenes">
      </div>
    </div>
    <whatsapp-float></whatsapp-float>
  </div>

  <!-- <div class="modal-footer">
		<button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
	</div> -->
</ng-template>
