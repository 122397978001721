import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, } from '@angular/core';
import { Product } from 'src/app/core/interfaces/featuredproducts';
import { ProductsService } from '@services/products.service';
import { UtilsService } from '@root/utilities/utils.service';
import { ProductFacadeService } from './product-facade.service';
import { Subscription, Observable, of } from 'rxjs';
import { Title } from '@interfaces/title';
import { UserModel } from '@models/user.model';
import { FavoriteFacadeService } from '../favorites/store/favorites-facade.service';
import { CategoriesFacadeService } from '../categories/categories-facade.service';
import { CategoriesMock } from '../categories/categories.mocks';
import { Colors } from '@interfaces/colors.interface';
import { MessageService } from '../../../core/services/message.service';
import { NavCategories } from '@interfaces/categories.response.interface';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@root/shared/shared.module';
import { FilterComponent } from './components/filter/filter.component';
import { DataService } from '../../../core/services/Data/data.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FilterComponent
  ]
})
export class ProductComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() products: Product[];
  @ViewChild('aForm') aForm: ElementRef;
  public produtcsAll: Product[] = [];
  public query: string;
  public subs: Subscription = new Subscription();
  public title: Title = {
    icon: 'assets/img/search-icon.png',
    name: 'Artículos',
  };
  public user: UserModel;
  public totalPages: any[] = [];
  public lastPage: number;
  public currentPage: number;
  public category: any;
  public favoriteList: Product[] = [];
  public productCategory: string = 'yes';
  public activeMenu = false;
  public colors: Colors[];
  public currentCategory: NavCategories;
  public allCategories: any[];
  public showInfo: boolean = false;
  public colorsAll: Colors[]
  constructor(
    private productService: ProductsService,
    private route: ActivatedRoute,
    private utilService: UtilsService,
    private productFacade: ProductFacadeService,
    private favoriteFacade: FavoriteFacadeService,
    private categoriesFacade: CategoriesFacadeService,
    private messageService: MessageService,
    private dataService: DataService
  ) {
    this.getAllColors();
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.inicio();
    this.utilService.footerShow(true);
  }

  ngOnInit(): void {
    this.dataService.getProductsList$().subscribe(data => {
      this.products = [];
      if (data) {
        this.produtcsAll = data.data;
        this.products = data.data
        this.getColorsAviable()
      }
    })
  }
  subcriptions() {
    const sub = this.favoriteFacade.getFavorite$().subscribe((fav) => {
      if (fav) {
        this.favoriteList = [...fav];
        this.updateFavorites();
      }
    });
    this.subs.add(sub);
    const sub5: Subscription = this.productFacade
      .getAllProduct$()
      .subscribe((productsAll: any) => {
        if (productsAll.length == 0) {
          this.showInfo = true;
          return;
        }
        this.showInfo = false;
        this.produtcsAll = [];
        let newArray: Product[] = [];
        productsAll.forEach((items) => {
          newArray.push({
            id: items.id,
            name: items.name,
            ref: items.id,
            price: items.price,
            image: items.image,
            category_id: null,
            vendor_id: items.vendor_id,
            sale_price: items.sale_price,
            regular_price: items.regular_price,
            sizes: items.sizes,
            colors: items.colors,
            discount: items.discount,
            slug: items.slug
          });
        });
        this.createPaginate(productsAll[0].last_page);
        this.produtcsAll = newArray;
        this.products = this.produtcsAll;
      });
    this.subs.add(sub5);
  }

  filtro($event: number) {
    this.category = $event;
    if ($event) {
      // this.getProductbycategories(1, $event);
    }
  }

  prueba() {
    this.productFacade.searchOffersProduct();
  }
  inicio() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }
  filtroII($event: number) {
    this.utilService.loading();
    let search = this.produtcsAll.filter((e) => e.type_of_sale == $event);
    this.products = search || [];
    this.utilService.loading(false);
  }
  createPaginate(totalPage: number) {
    this.updateFavorites();
    this.lastPage = totalPage;
    this.totalPages = [];
    if (totalPage <= 0 || totalPage == null) {
      return;
    }
    for (let i = 1; i <= totalPage; i++) {
      this.totalPages.push({
        id: i,
        page: 'page' + i,
      });
    }
  }

  paginas(pagina: number) {
    // this.getProductbycategories(pagina, this.category);
  }
  previousNext(action: string) {
    switch (action) {
      case 'sig':
        this.currentPage += 1;
        // this.getProductbycategories(this.currentPage, this.category);
        break;
      case 'ant':
        this.currentPage -= 1;
        // this.getProductbycategories(this.currentPage, this.category);
        break;
    }
  }
  updateFavorites() {
    let nuevoArray = [];
    if (this.favoriteList && this.produtcsAll) {
      let favorite = false;
      this.products.forEach((item) => {
        let temp = {
          ...item,
          isFavorite:
            this.favoriteList.find((element) => element.id == item.id) !==
            undefined,
        };

        if (nuevoArray.find((e) => e.id == item.id) == undefined) {
          nuevoArray.push(temp);
        }
      });
      this.produtcsAll = [...nuevoArray];
      this.products = this.produtcsAll;
    }
  }
  changeCategory(): Observable<string> {
    return of(this.productCategory);
  }

  toggleFilterButton() {
    this.activeMenu = !this.activeMenu;
  }

  filterArray(products: any[], color: string): any[] {
    const filter = products.filter((e) => {
      const filterSub: any[] = e.colors.filter(
        (c) => c.name.toLowerCase() === color
      );
      return filterSub.length > 0;
    });
    return filter;
  }

  filterByColors(color: any) {
    if (color == "todos") {
      this.products = this.produtcsAll
      return;
    }
    if (this.filterArray(this.produtcsAll, color).length > 0) {
      this.products = this.filterArray(this.produtcsAll, color);
    } else {
      this.messageService.showCustom(
        `Lo sentimos por ahora el color ${color} no está Disponible`,
        null,
        'giovanye'
      );
    }
  }
  getAllColors() {
    this.productService
      .getAllColors()
      .subscribe((colors) => {
        this.colorsAll = [...colors]
        //this.colors = colors
      });
  }

  getCurrenCategory(slug: string) {
    this.currentCategory = null;
    if (this.allCategories) {
      this.currentCategory = <any>(
        this.allCategories.filter((item) => item.slug == slug)[0]
      );
    }
  }
  order(direction: string) {
    switch (direction) {
      case 'asc':
        this.products.sort((a: Product, b: Product) => (a.id > b.id) ? 1 : -1);
        break
      case 'desc':
        this.products.sort((a: Product, b: Product) => (a.id > b.id) ? -1 : 1);
        break
    }
  }
  getColorsAviable() {
    this.colors = [];
    const colorsMap = new Map<string, { name: string; code: string | null }>();

    // Verificar si `produtcsAll` tiene productos
    if (!this.produtcsAll || this.produtcsAll.length < 1) {
      return;
    }

    // Agregar colores únicos al mapa usando el `name` como clave
    this.produtcsAll.forEach(product => {
      product.colors.forEach(color => {
        // Clave única compuesta de `name` y `code`
        const key = `${color.name}-${color.code}`;
        if (!colorsMap.has(key)) {
          colorsMap.set(key, { name: color.name, code: color.code });
        }
      });
    });

    // Convertir el mapa en un array de colores únicos
    const uniqueColors = Array.from(colorsMap.values()).map(color => ({
      id: null, // Asignar un valor adecuado para `id` si es necesario
      name: color.name,
      code: color.code,
      deleted_at: null,
      created_at: null,
      updated_at: null,
    }));

    // Agregar la opción "Todos" al inicio del array de colores
    this.colors = [
      {
        id: 9999,
        name: "Todos",
        code: null,
        deleted_at: null,
        created_at: "2022-03-14 13:16:16",
        updated_at: "2022-06-10 08:02:43",
      },
      ...uniqueColors,
    ];
  }

}
