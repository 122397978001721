import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { FeaturedProductsComponent } from './features/featured-products/featured-products.component';
import { FrequentQuestionComponent } from './features/frequent-question/frequent-question.component';
import { FeaturedProductsCompanyComponent } from './features/featured-products-company/featured-products-company.component';
import { PaymentMethodComponent } from './features/payment-method/payment-method.component';
import { TraceGuideComponent } from './features/trace-guide/trace-guide.component';
import { AssignedConsultantComponent } from './features/assigned-consultant/assigned-consultant.component';
import { DeliveryAddressComponent } from './features/delivery-address/delivery-address.component';
import { LastPurchasesResumenComponent } from './features/last-purchases-resumen/last-purchases-resumen.component';
import { AuthGuard } from '@services/auth.guard';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ToolsComponent } from './pages/tools/tools.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'landingpage', component: LandingComponent
  },
  {
    path: 'tools', component: ToolsComponent
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'page-404',
    loadChildren: () =>
      import('./features/page404/page404.module').then(
        (m) => m.Page404Module
      ),
  },
  {
    path: 'registro',
    loadChildren: () =>
      import('./features/sing-up/sing-up.component.module').then(
        (m) => m.SingUpModule
      ),
  },
  {
    path: 'lista-articulos',
    loadChildren: () =>
      import('./pages/products-list/products-list.module').then(
        (m) => m.ProductListModule
      ),
  },
  {
    path: 'busqueda', component: SearchResultComponent
  },
  {
    path: 'articulo',
    loadChildren: () =>
      import('./pages/product-home/product-home.module').then(
        (m) => m.ProductHomeModule
      ),
  },
  {
    path: 'contactanos',
    loadChildren: () =>
      import('./features/sell-w-us/sell-w-us.module').then(
        (m) => m.SellWUsHomeModule
      ),
  },
  {
    path: 'types',
    loadChildren: () =>
      import('./features/types/types.component.module').then(
        (m) => m.TypesModule
      ),
  },
  {
    path: 'empresas-oficiales',
    loadChildren: () =>
      import('./features/officialcompany/officialcompany.module').then(
        (m) => m.OfficialCompanyModule
      ),
  },
  {
    path: 'carrito-de-compras',
    loadChildren: () =>
      import('./pages/cart/cart.module').then(
        (m) => m.CartComponentHomeModule
      ),
  },

  // TODO: RUTAS PROTEGIDAS
  {
    path: 'ultimas-compras',
    loadChildren: () =>
      import('./features/last-purchases/last-purchases.module').then(
        (m) => m.LastPurchasesComponentHomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import(
        './features/user-profile/user-profile.component.module'
      ).then((m) => m.UserPerfilModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contactenos',
    loadChildren: () =>
      import('./features/contact-us/contact-us.module').then(
        (m) => m.ContactUsHomeModule
      ),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./shared/components/logout/logout.module').then(
        (m) => m.LogoutModule
      ),
  },
  {
    path: 'gestion-de-compras',
    loadChildren: () =>
      import(
        './features/purchase-management/purchase-management.module'
      ).then((m) => m.PurchaseManagementHomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'direccion-de-envio',
    loadChildren: () =>
      import('./features/delivery-address/delivery-address.module').then(
        (m) => m.DeliveryAddressHomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pago',
    loadChildren: () =>
      import('./pages/payment/payment.module').then(
        (m) => m.PaymentComponentHomeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'vendor',
    loadChildren: () =>
      import('./features/vendor/vendor.module').then(
        (m) => m.VendorModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'lista-ultimas-compras',
    loadChildren: () =>
      import(
        './features/list-last-purchases/list-last-purchases.module'
      ).then((m) => m.ListLastPurchasesModule),
  },
  {
    path: 'ver-compra',
    loadChildren: () =>
      import('./features/purchases-viewer/purchases-viewer.module').then(
        (m) => m.PurchasesViewerModule
      ),
  },
  {
    path: 'edit-direcciones-de-entrega',
    loadChildren: () =>
      import('./features/edit-address/edit-address.module').then(
        (m) => m.EditAddressHomeModule
      ),
  },
  {
    path: 'last-purchases-resumen',
    component: LastPurchasesResumenComponent,
  },
  {
    path: 'featured-products',
    component: FeaturedProductsComponent,
  },
  {
    path: 'featured-products-company',
    component: FeaturedProductsCompanyComponent,
  },
  {
    path: 'assigned-consultant',
    component: AssignedConsultantComponent,
  },
  {
    path: 'trace-guide',
    component: TraceGuideComponent,
  },
  {
    path: 'direcciones-entrega',
    component: DeliveryAddressComponent,
  },
  {
    path: 'payment-method',
    component: PaymentMethodComponent,
  },
  {
    path: 'frequent-question',
    component: FrequentQuestionComponent,
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () =>
      import('./features/faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'preguntas-frecuentes-respuestas/:tipo',
    loadChildren: () =>
      import('./features/faqs-respnse/faqs-respnse.module').then(
        (m) => m.FaqsRespnseModule
      ),
  },
  {
    path: 'pqr',
    loadChildren: () =>
      import('./features/pqr/pqr.module').then((m) => m.PqrModule),
  },
  {
    path: 'politicas-datos',
    loadChildren: () =>
      import(
        './features/data-treatment-policy/data-treatment-policy.module'
      ).then((m) => m.DataTreatmentPolicyModule),
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import(
        './features/privacy-notice-cookies/privacy-notice-cookies.module'
      ).then((m) => m.PrivacyNoticeCookiesModule),
  },
  {
    path: 'políticas-de-cambio-y-devolución',
    loadChildren: () =>
      import(
        './features/exchange-policies/exchange-policies.module'
      ).then((m) => m.ExchangePoliciesModule),
  },
  {
    path: 'acerca-de',
    loadChildren: () =>
      import('./features/about-us/about-us.module').then(
        (m) => m.AboutUsModule
      ),
  },
  {
    path: 'redirectToWhatsapp',
    loadChildren: () =>
      import(
        './features/redirect-to-whastapp/redirect-to-whastapp.module'
      ).then((m) => m.RedirectToWhastappModule),
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./features/categories-page/categories-page.module').then(
        (m) => m.CategoriesPageModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./features/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'localiza-tu-tienda',
    loadChildren: () =>
      import('./features/map-store/map-store.module').then(
        (m) => m.MapStoreModule
      ),
  },
  {
    path: 'favoritos',
    loadChildren: () =>
      import('./features/wish-list/wish-list.module').then(
        (m) => m.WishListModule
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
