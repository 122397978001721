import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'whatsapp-float',
  templateUrl: './whatsapp-float.component.html',
  styleUrls: ['./whatsapp-float.component.scss']
})
export class WhatsappFloatComponent implements OnInit {
  @Input() logoWhite = true;
  constructor() { }

  ngOnInit(): void {
  }

}
