
	<div class="modal-header">
		<div class="modal-title text-center">Tallas Disponibles</div>
		<!-- <span aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></span> -->
	</div>
	<div class="modal-body">
		<div class="container-fluid">
			<div class="sizes font-title">
				<span class="sizes__title">Talla</span>
				<div class="sizes__btn">
					<mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style">
						<mat-button-toggle class="product-btn" value="{{size.size}}" *ngFor="let size of sizesAviables"
								(click)="onClick(size)">{{size.size}}
						</mat-button-toggle>
				</mat-button-toggle-group>
					<a class="sizes-guide" href="../../../../assets/files/tabla de medidas GIOVANYE .pdf"  target="_blank" >
						GUIA DE TALLAS
						<img class="sizes-guide__image" src="../../../../assets/img/desktop/webp/rule.webp" alt="">
						<span></span>
					</a>
				</div>
			</div>
		</div>
	</div>
