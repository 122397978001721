<div class="pad-top"></div>
<div class="container-fluid mt-4 ps-4">
  <h2 class="text-center">Herramientas GIOVANYE</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="convert">Convertidor imagenes a formato WEBP</label>
        <input id="convert" class="form-control" type="file"
          (change)="onFilesSelected($event)"
          multiple accept="image/*" />
          <small id="emailHelp" class="form-text text-muted">Seleccione uno o mas archivos a convertir</small>
      </div>
    </div>
  </div>
</div>
