import { ProductHgi } from './../interfaces/store.interface';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryModel } from '@models/category.model';
import { Product } from '@interfaces/featuredproducts';
import { ResponseProducts } from '@interfaces/response-products.interface';
import { Colors } from '@interfaces/colors.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjA4ZDE1NGJjMTRkZTMyMmY5ZWU5MjdmZjQwNTFmZmM2YjJjMTAzZjcyYjM5MDQ3Y2I0MzYyODhhMjVlYmQ2ZDYyNmY0NjEwNjc3NTI5YzQyIn0.eyJhdWQiOiIyIiwianRpIjoiMDhkMTU0YmMxNGRlMzIyZjllZTkyN2ZmNDA1MWZmYzZiMmMxMDNmNzJiMzkwNDdjYjQzNjI4OGEyNWViZDZkNjI2ZjQ2MTA2Nzc1MjljNDIiLCJpYXQiOjE2MDk3ODQxMzAsIm5iZiI6MTYwOTc4NDEzMCwiZXhwIjoxNjExMDgwMTMwLCJzdWIiOiIxMjIiLCJzY29wZXMiOltdfQ.qcSmMQt7bRrEo11xWQnCLQVRLsbmLFJX6-mAygtHOT2eYJzHtuHM3v94jrcd2yWLFwZ89hMF15V9Nua_BmAH5ReMuyJCOGyJZYsytqEFh-L3AyLIqb_ls3jEbZvlCkivl-3bzAOjavI24fMu5kE2on9ksIMv05Nn92gWXLhX2fOrD4dn1YcCriOvRwM9nL0wLeyaQARc83DgYVKzT7FPqz9sfKTtGNCNPPhFcetpEXZnWuBPWtsY7nOem9_Djy-C-IFexWiE8DFmQkRy-DaOPZq5hpcYvmQBlDHDsBIKX_tWSFALwP-Sd4E7kSFLC7d2rmmoSP_iQpQu8U-c0JMOxldTgaXGbz91wEaNKZQ8eAAlUDC93z-uf97zO-dnMhoT3-b9hnh_mVjIIBpUhqRdQ_VQ-ct0zmmAXzXF5jZh7Du7PUbWpL3E2MK1mkxSORv5XXAI71Ro0UdlU2ukuusBR7se-SJ4wTQAJ_agtMX93JXjF_gm2sbi10jdRyEDFbp2yQflZprYUASRM82WW5jTABV3VCQyUE2_8jZkXLvESD__-Quq7Lpop22aPGFFNl_fbQydjthfpQDO955Genybul77FU8ZtAhowf4XlLV74Ik2a6uC_8LEHB2Zzr1x3gq5KwfpDrC6JBKrlKZDIiy4zx7LwocBDLPZaR-823ahUys';
  urlApi: string = environment.API_URL;
  storage: string = environment.STORAGE;
  Headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token,
    }),
  };

  public setHttpOption() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.token,
      }),
    };
  }

  public mostSelledProdutc(): Observable<any> {
    return this.http
      .get<any>(
        `${this.urlApi}products/get-top-products`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          let Product: any[] = [];
          resp.forEach((element) => {
            Product.push({
              id: element.product.id,
              productName: element.name,
              ref: element.product.id,
              price: element.product.regular_price,
              isFavorite: false,
              image: this.storage + element.product.image,
            });
          });

          return Product;
        })
      );
  }
  public getTopProducts(): Observable<any> {
    return this.http
      .get<any>(
        `${this.urlApi}products/get-top-products`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          let Product: any[] = [];
          resp.forEach((element) => {
            Product.push({
              id: element.product.id,
              ref: element.sku,
              name: element.product.name,
              price: element.product.regular_price,
              image:
                'https://api.obbro.co/storage/' +
                element.product.image,
              type_of_sale: element.type_of_sale,
              dataSheet: element.link,
            });
          });

          return Product;
        })
      );
  }
  public allProdutc(): Observable<any> {
    const options = {
      params: new HttpParams().append('rows', 1000 + ''),
      body: new HttpParams().append('rows', 1000 + ''),
    };
    return this.http.get<any>(`${this.urlApi}products/active`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  public offersProdutc(): Observable<any> {
    const url = `${this.urlApi}products/offers`
    return this.http.get<any>(url).pipe(
      map((resp) => {
        let Product: any[] = [];
        resp.data.forEach((element) => {
          Product.push({
            id: element.id,
            name: element.name,
            ref: element.sku,
            price: element.regular_price,
            regular_price: element.regular_price,
            sale_price: element.sale_price,
            isFavorite: false,
            image: element.image,
            category_id: element.category_id,
            slug: element.slug,
            type_of_sale: element.type_of_sale,
            dataSheet: element.link,
            colors: element.colors,
            sizes: element.sizes,
            discount: element.discount,
          });
        });

        return Product;
      })
    );
  }
  public getProductsByCategory(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.urlApi}categories/${id}/products`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  public getProduct(id: string): Observable<any> {
    return this.http.get<any>(`${this.urlApi}products/${id}`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  public;
  getProductByVendor(
    idVendor: number,
    page: number = 3,
    filter?: number
  ): Observable<any> {
    if (filter) {
      return this.http
        .get<any>(
          `${this.urlApi}products/${idVendor}/${filter}/getProductsByVendor?page=${page}`
        )
        .pipe(
          // tap(e => this.utilService.consoleLog(e)),
          map((resp) => {
            let Product: any[] = [];
            resp.data.forEach((element) => {
              if (
                Product.find(
                  (e) => e.id == element.product_id
                ) == undefined
              ) {
                Product.push({
                  id: element.product_id,
                  name: element.name,
                  ref: element.sku,
                  price: element.regular_price,
                  offerPrice: element.sale_price,
                  isFavorite: false,
                  image: this.storage + element.image,
                  category_id: element.category_id,
                  type_of_sale: element.type_of_sale,
                  dataSheet: element.link,
                  last_page: resp.last_page,
                  totalItems: resp.total,
                });
              }
            });
            return Product;
          })
        );
    } else {
      return this.http
        .get<any>(
          `${this.urlApi}products/${idVendor}/getProductsByVendor?page=${page}`
        )
        .pipe(
          // tap(e => this.utilService.consoleLog(e)),
          map((resp) => {
            let Product: any[] = [];
            resp.data.forEach((element) => {
              Product.push({
                id: element.product_id,
                name: element.name,
                ref: element.sku,
                price: element.regular_price,
                offerPrice: element.sale_price,
                isFavorite: false,
                image: this.storage + element.image,
                category_id: element.category_id,
                type_of_sale: element.type_of_sale,
                dataSheet: element.link,
                last_page: resp.last_page,
                totalItems: resp.total,
              });
            });
            return Product;
          })
        );
    }
  }

  public getProductsbyCategory(id: any): Observable<Product[]> {
    const url = `${this.urlApi}categories/${id}/products`
    return this.http.get<Product[]>(url).pipe(map((resp) => { return resp; }));
  }
  public getProductsBySlug(slug: string): Observable<Product[]> {
    return this.http
      .get<Product[]>(`${this.urlApi}categories/${slug}/products`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  public getCategoriesProductbyVendors(
    id: number
  ): Observable<CategoryModel[]> {
    return this.http
      .get<CategoryModel[]>(`${this.urlApi}categories/${id}/byvendors`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  public getCategoriesTree$(): Observable<any[]> {
    return this.http.get<any[]>(`${this.urlApi}categories/tree`);
  }
  public searchProduct(query: string, rows: number = 1000): Observable<any> {
    const options = {
      search: query,
      rows: rows,
    };
    return this.http
      .post<any>(`${this.urlApi}products/search`, options)
      .pipe(
        map((resp) => {
          let Product: any[] = [];
          resp.data.forEach((element) => {
            Product.push({
              id: element.id,
              name: element.name,
              ref: element.sku,
              price: element.regular_price || element.sale_price,
              isFavorite: false,
              image: element.image,
              category_id: element.category_id,
              vendor_id: element.vendor_id,
              type_of_sale: element.type_of_sale,
              dataSheet: element.link,
            });
          });

          return Product;
        })
      );
  }
  public getProductAvailability$(sku: string): Observable<ProductHgi> {
    return this.http
      .get<ProductHgi>(`${this.urlApi}products/${sku}/checkqty`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  public getProductsByQuery$(query: string): Observable<ResponseProducts> {
    const params = query && query !== '' ? `?search=${query}` : '';
    const url = `${this.urlApi}products${params}`;
    return this.http.get<ResponseProducts>(url);
  }

  public getPromotedProducts$(): Observable<ResponseProducts> {
    const url = `${this.urlApi}products/get-promoted-products`;
    return this.http.get<ResponseProducts>(url);
  }
  public getAllColors(): Observable<Colors[]> {
    const url = `${this.urlApi}products/colors`;
    return this.http.get<Colors[]>(url);
  }
}
