<div class="container-wrapper">
  <div class="img-menu-category" *ngIf="currentCategory">
    <img
      [src]="currentCategory.image_main"
      class="img-fluid"
      *ngIf="currentCategory.image_main"
      [alt]="currentCategory?.name" />
    <div class="img-menu-category-description">
      <h3>{{ currentCategory.title_image_main }}</h3>
      <p class="product-text">{{ currentCategory.description_image_main }}</p>
    </div>
  </div>

  <div class="product">
    <div class="filter-option font-text">
      <div class="dropdown filter-description-product">
        <gvy-filter
          [colors]="colors"
          (onColorSelected)="filterByColors($event)"
          (onOrderSelected)="order($event)">
        </gvy-filter>
      </div>
      <div class="total-p" *ngIf="products">
        {{ products.length ?? 0 }} PRODUCTS
      </div>
    </div>

    <div class="product__alert my-5" *ngIf="!products || products.length < 1">
      <div class="row d-flex justify-content-center">
        <div class="col-sm-12">
          <div class="jumbotron jumbotron-fluid d-flex justify-content-center">
            <div class="container text-center">
              <h1 class="display-3">Giovanye</h1>
              <p class="lead">Pronto podras ver nuestra próxima Colección</p>
              <p class="lead">Por favor visita otras categorias</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="product__cards container mx-auto"
      *ngIf="products && products.length > 0">
      <div class *ngFor="let product of products">
        <app-products-card
          [product]="product"
          class="animate animate__fadeIn"></app-products-card>
      </div>
    </div>
    <div></div>

    <div class="row pb-5 my-5" *ngIf="totalPages && totalPages.length > 0">
      <div class="col-sm-12 pagination-page">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="text-white mr-3">Páginas anterior</li>
            <li [class]="currentPage == 1 ? 'page-item disabled' : 'page-item'">
              <a
                class="page-link saltos"
                tabindex="-1"
                aria-disabled="true"
                (click)="previousNext('ant')">
                &#60;-
              </a>
            </li>

            <li
              [class]="
                currentPage == item.id
                  ? 'page-item active paginationResponsive'
                  : 'page-item paginationResponsive'
              "
              *ngFor="let item of totalPages">
              <a class="page-link" (click)="paginas(item.id)">
                {{ item.id }}
              </a>
            </li>

            <li class="paginationResponsive_List">
              <a class="page-link respLink">
                {{ currentPage }} de {{ lastPage }}</a>
            </li>
            <li
              [class]="
                currentPage == lastPage
                  ? 'page-item saltos disabled'
                  : 'page-item saltos'
              ">
              <a class="page-link next-page"
                (click)="previousNext('sig')">-&#62;</a>
            </li>
            <li class="text-white d-none d-lg-block">Siguiente</li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>

<!-- <button class="btn btn-danger" (click)="subir()"> subir</button> -->
