import { FooterMenuList } from './../shared/components/footer/footer-list.interface';

export const footerMenuListData: FooterMenuList[] = [
    {
        class: 'about',
        header: 'SERVICIO AL CLIENTE',
        items: [
            {
                type: 'link',
                link: '/preguntas-frecuentes',
                label: 'Preguntas Frecuentes'
            },
            {
                type: 'link',
                link: '/terms',
                label: 'Términos y condiciones'
            },
            {
                type: 'link',
                link: '',
                label: 'Línea nacional. 604 265 92 52'
            },
            {
                type: 'link',
                link: '',
                label: 'servicioalcliente@giovanye.com.co'
            },
            {
                type: 'link',
                link: '/localiza-tu-tienda',
                label: 'ENCUENTRA TU TIENDA'
            },
        ]
    },
    {
        class: 'us',
        header: '',
        items: [
            {
                type: 'link',
                link: '/cookies',
                label: 'Política de Cookies',
                target: ''
            },
            {
                type: 'link',
                link: '/políticas-de-cambio-y-devolución',
                label: 'Peticiones, Quejas y Reclamos',
                target: ''
            },
            {
                type: 'link',
                link: '/terms',
                label: 'Términos y condiciones promociones',
                target: ''
            },
            {
                type: 'link',
                link: '/terms',
                label: 'Terminos y condiciones recogida en tienda',
                target: ''
            },
            {
                type: 'link',
                link: '/terms',
                label: 'Términos y condiciones pago contra entrega',
                target: ''
            },
            {
                type: 'link',
                link: '/políticas-de-cambio-y-devolución',
                label: 'Cambios, Garantías, Retracto y Reversión del pago'
            },

        ]
    },
    {
        class: 'brand',
        header: 'MARCA',
        items: [
            {
                type: 'link',
                link: '',
                label: 'Estado de orden'
            },
            {
                type: 'link',
                link: '/acerca-de',
                label: 'Historia de la marca',
                target: ''
            },

            {
                type: 'link',
                link: '',
                label: '¡SOLICITUD DE CAMBIO O SUGERENCIAS !'
            },

        ]
    },
    {
        class: 'follow',
        header: '',
        items: [
            {
                type: 'social',
                link: '',
                image: 'assets/img/footer-app-store-icon.jpg',
                label: '',
                socialItems: [
                    {
                        label: 'Instagram',
                        image: 'fa-square-instagram',
                        link: 'https://www.instagram.com/giovanyeoficial/'
                    },
                    {
                        label: 'Twitter',
                        image: 'fa-square-twitter',
                        link: ''
                    },
                    {
                        label: 'Pinterest',
                        image: 'fa-square-pinterest',
                        link: ''
                    },
                    {
                        label: 'Facebook',
                        image: 'fa-square-facebook',
                        link: 'https://www.facebook.com/oficialgiovanye'
                    },
                    {
                        label: 'Youtube',
                        image: 'fa-youtube',
                        link: ''
                    }
                ]
            }
        ]
    },

];
